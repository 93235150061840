define('@fortawesome/pro-thin-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faGrid = {
    prefix: 'fat',
    iconName: 'grid',
    icon: [448, 512, ["grid-3"], "e195", "M88 48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H40c-13.3 0-24-10.7-24-24V72c0-13.3 10.7-24 24-24H88zM40 32C17.9 32 0 49.9 0 72v48c0 22.1 17.9 40 40 40H88c22.1 0 40-17.9 40-40V72c0-22.1-17.9-40-40-40H40zM88 208c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H40c-13.3 0-24-10.7-24-24V232c0-13.3 10.7-24 24-24H88zM40 192c-22.1 0-40 17.9-40 40v48c0 22.1 17.9 40 40 40H88c22.1 0 40-17.9 40-40V232c0-22.1-17.9-40-40-40H40zm0 176H88c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H40c-13.3 0-24-10.7-24-24V392c0-13.3 10.7-24 24-24zM0 392v48c0 22.1 17.9 40 40 40H88c22.1 0 40-17.9 40-40V392c0-22.1-17.9-40-40-40H40c-22.1 0-40 17.9-40 40zM248 48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H200c-13.3 0-24-10.7-24-24V72c0-13.3 10.7-24 24-24h48zM200 32c-22.1 0-40 17.9-40 40v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V72c0-22.1-17.9-40-40-40H200zm0 176h48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H200c-13.3 0-24-10.7-24-24V232c0-13.3 10.7-24 24-24zm-40 24v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V232c0-22.1-17.9-40-40-40H200c-22.1 0-40 17.9-40 40zm88 136c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H200c-13.3 0-24-10.7-24-24V392c0-13.3 10.7-24 24-24h48zm-48-16c-22.1 0-40 17.9-40 40v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V392c0-22.1-17.9-40-40-40H200zM360 48h48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H360c-13.3 0-24-10.7-24-24V72c0-13.3 10.7-24 24-24zM320 72v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V72c0-22.1-17.9-40-40-40H360c-22.1 0-40 17.9-40 40zm88 136c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H360c-13.3 0-24-10.7-24-24V232c0-13.3 10.7-24 24-24h48zm-48-16c-22.1 0-40 17.9-40 40v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V232c0-22.1-17.9-40-40-40H360zm0 176h48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H360c-13.3 0-24-10.7-24-24V392c0-13.3 10.7-24 24-24zm-40 24v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V392c0-22.1-17.9-40-40-40H360c-22.1 0-40 17.9-40 40z"]
  };
  var faPencil = {
    prefix: 'fat',
    iconName: 'pencil',
    icon: [512, 512, [9999, 61504, "pencil-alt"], "f303", "M395 25.9c12.5-12.5 32.8-12.5 45.3 0l45.8 45.8c12.5 12.5 12.5 32.8 0 45.3l-66.9 66.9L328.1 92.8 395 25.9zm-78.2 78.2l91.1 91.1L164.7 438.4c-1.5 1.5-3.1 3-4.7 4.4V408c0-4.4-3.6-8-8-8H112V360c0-4.4-3.6-8-8-8H69.2c1.4-1.6 2.9-3.2 4.4-4.7L316.8 104.1zM51.4 384.7c1.7-5.8 4-11.4 6.8-16.7H96v40c0 4.4 3.6 8 8 8h40v37.7c-5.3 2.8-10.9 5.1-16.7 6.8L19.8 492.2 51.4 384.7zm400.1-370c-18.7-18.7-49.1-18.7-67.9 0L62.3 336C50 348.3 41 363.5 36.1 380.2L.3 501.7c-.8 2.8-.1 5.8 2 7.9s5.1 2.8 7.9 2l121.6-35.8c16.7-4.9 31.9-13.9 44.2-26.2L497.4 128.3c18.7-18.7 18.7-49.1 0-67.9L451.5 14.6zM317.7 173.1c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0l-144 144c-3.1 3.1-3.1 8.2 0 11.3s8.2 3.1 11.3 0l144-144z"]
  };
  var faBars = {
    prefix: 'fat',
    iconName: 'bars',
    icon: [448, 512, ["navicon"], "f0c9", "M0 88c0-4.4 3.6-8 8-8H440c4.4 0 8 3.6 8 8s-3.6 8-8 8H8c-4.4 0-8-3.6-8-8zM0 248c0-4.4 3.6-8 8-8H440c4.4 0 8 3.6 8 8s-3.6 8-8 8H8c-4.4 0-8-3.6-8-8zM448 408c0 4.4-3.6 8-8 8H8c-4.4 0-8-3.6-8-8s3.6-8 8-8H440c4.4 0 8 3.6 8 8z"]
  };
  var faArrowRightFromBracket = {
    prefix: 'fat',
    iconName: 'arrow-right-from-bracket',
    icon: [512, 512, ["sign-out"], "f08b", "M509.7 261.7c3.1-3.1 3.1-8.2 0-11.3l-136-136c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3L484.7 248 168 248c-4.4 0-8 3.6-8 8s3.6 8 8 8l316.7 0L362.3 386.3c-3.1 3.1-3.1 8.2 0 11.3s8.2 3.1 11.3 0l136-136zM184 48c4.4 0 8-3.6 8-8s-3.6-8-8-8L88 32C39.4 32 0 71.4 0 120L0 392c0 48.6 39.4 88 88 88l96 0c4.4 0 8-3.6 8-8s-3.6-8-8-8l-96 0c-39.8 0-72-32.2-72-72l0-272c0-39.8 32.2-72 72-72l96 0z"]
  };
  var faSignOut = faArrowRightFromBracket;
  var faUsers = {
    prefix: 'fat',
    iconName: 'users',
    icon: [640, 512, [], "f0c0", "M80 80a64 64 0 1 1 128 0A64 64 0 1 1 80 80zm144 0A80 80 0 1 0 64 80a80 80 0 1 0 160 0zm96 64a80 80 0 1 1 0 160 80 80 0 1 1 0-160zm0 176a96 96 0 1 0 0-192 96 96 0 1 0 0 192zm-58.7 48H378.7C443.5 368 496 420.5 496 485.3c0 5.9-4.8 10.7-10.7 10.7H154.7c-5.9 0-10.7-4.8-10.7-10.7C144 420.5 196.5 368 261.3 368zm0-16C187.7 352 128 411.7 128 485.3c0 14.7 11.9 26.7 26.7 26.7H485.3c14.7 0 26.7-11.9 26.7-26.7C512 411.7 452.3 352 378.7 352H261.3zM512 16a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm0 144A80 80 0 1 0 512 0a80 80 0 1 0 0 160zm24 48c48.6 0 88 39.4 88 88c0 4.4 3.6 8 8 8s8-3.6 8-8c0-57.4-46.6-104-104-104H444c1.3 5.2 2.4 10.6 3 16h89zm-343 0c.7-5.4 1.7-10.8 3-16H104C46.6 192 0 238.6 0 296c0 4.4 3.6 8 8 8s8-3.6 8-8c0-48.6 39.4-88 88-88h89z"]
  };
  var faUser = {
    prefix: 'fat',
    iconName: 'user',
    icon: [448, 512, [128100, 62144], "f007", "M336 128a112 112 0 1 0 -224 0 112 112 0 1 0 224 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM16 482.3c0 7.6 6.1 13.7 13.7 13.7H418.3c7.6 0 13.7-6.1 13.7-13.7C432 392.7 359.3 320 269.7 320H178.3C88.7 320 16 392.7 16 482.3zm-16 0C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"]
  };
  var faUserTie = {
    prefix: 'fat',
    iconName: 'user-tie',
    icon: [448, 512, [], "f508", "M224 240a112 112 0 1 0 0-224 112 112 0 1 0 0 224zM352 128A128 128 0 1 1 96 128a128 128 0 1 1 256 0zM176.9 308.3c1.4-2.7 4.1-4.3 7.1-4.3h80c3 0 5.7 1.7 7.1 4.3s1.1 5.9-.6 8.3l-37.2 52.1 23.2 46.3 47.1-94.2c81.2 8.4 144.4 77 144.4 160.4c0 17-13.8 30.7-30.7 30.7H225.9h-3.8H30.7C13.8 512 0 498.2 0 481.3c0-83.4 63.3-152 144.4-160.4l47.1 94.2 23.2-46.3-37.2-52.1c-1.7-2.4-2-5.6-.6-8.3zM199.5 320L224 354.2 248.5 320H199.5zm10.1 167.2L135.3 338.3C67.5 350.6 16 409.9 16 481.3c0 8.1 6.6 14.7 14.7 14.7H214.1l-4.4-8.8zm-9.2-54.2l14.6 29.2L224 480l8.9-17.9 14.6-29.2L224 385.9l-23.5 47.1zM432 481.3c0-71.4-51.5-130.7-119.3-143L238.3 487.2l-4.4 8.8H417.3c8.1 0 14.7-6.6 14.7-14.7z"]
  };
  var faCircleQuestion = {
    prefix: 'fat',
    iconName: 'circle-question',
    icon: [512, 512, [62108, "question-circle"], "f059", "M496 256A240 240 0 1 0 16 256a240 240 0 1 0 480 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm168-56c0-30.9 25.1-56 56-56h56.2c35.2 0 63.8 28.6 63.8 63.8c0 21.4-10.8 41.5-28.7 53.3L264 294.9V320c0 4.4-3.6 8-8 8s-8-3.6-8-8V290.5c0-2.7 1.4-5.2 3.6-6.7l54.9-36.2c13.4-8.8 21.5-23.8 21.5-39.9c0-26.4-21.4-47.8-47.8-47.8H224c-22.1 0-40 17.9-40 40l0 6.5c0 4.4-3.6 8-8 8s-8-3.6-8-8l0-6.5zm72 168a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z"]
  };
  var faSpinnerThird = {
    prefix: 'fat',
    iconName: 'spinner-third',
    icon: [512, 512, [], "f3f4", "M470.8 380c3.8 2.2 8.7 .9 10.8-3C501 341 512 299.8 512 256C512 117.3 401.7 4.4 264 .1c-4.4-.1-8 3.5-8 7.9s3.6 8 8 8.1c128.8 4.2 232 110 232 239.9c0 40.9-10.2 79.3-28.2 113c-2.1 3.9-.8 8.8 3 11z"]
  };
  var faCircleInfo = {
    prefix: 'fat',
    iconName: 'circle-info',
    icon: [512, 512, ["info-circle"], "f05a", "M256 16a240 240 0 1 1 0 480 240 240 0 1 1 0-480zm0 496A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM208 352c-4.4 0-8 3.6-8 8s3.6 8 8 8h96c4.4 0 8-3.6 8-8s-3.6-8-8-8H264V216c0-4.4-3.6-8-8-8H224c-4.4 0-8 3.6-8 8s3.6 8 8 8h24V352H208zm48-176a16 16 0 1 0 0-32 16 16 0 1 0 0 32z"]
  };
  var faInfoCircle = faCircleInfo;
  var faRotateExclamation = {
    prefix: 'fat',
    iconName: 'rotate-exclamation',
    icon: [512, 512, [], "e23c", "M256 48c65.3 0 123.6 30.1 161.7 77.2L456 176H376c-4.4 0-8 3.6-8 8s3.6 8 8 8h96c4.4 0 8-3.6 8-8V88c0-4.4-3.6-8-8-8s-8 3.6-8 8v72.1l-33.6-44.6 0 0-.2-.2C389.2 64.5 326.4 32 256 32C155.4 32 70.3 98.3 42 189.6c-1.3 4.2 1.1 8.7 5.3 10s8.7-1.1 10-5.3C83.5 109.6 162.6 48 256 48zm8 88c0-4.4-3.6-8-8-8s-8 3.6-8 8V296c0 4.4 3.6 8 8 8s8-3.6 8-8V136zm-8 232a16 16 0 1 0 0-32 16 16 0 1 0 0 32zM32 424c0 4.4 3.6 8 8 8s8-3.6 8-8V349.8l35.2 46.8 0 0 .2 .2C124.4 447.5 187.2 480 257.6 480c100.6 0 185.7-66.3 214-157.6c1.3-4.2-1.1-8.7-5.3-10s-8.7 1.1-10 5.3C430.1 402.4 351 464 257.6 464c-65.3 0-123.6-30.1-161.7-77.2L57.7 336H136c4.4 0 8-3.6 8-8s-3.6-8-8-8H40c-4.4 0-8 3.6-8 8v96z"]
  };
  var faChevronDown = {
    prefix: 'fat',
    iconName: 'chevron-down',
    icon: [512, 512, [], "f078", "M250.3 397.7c3.1 3.1 8.2 3.1 11.3 0l216-216c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0L256 380.7 45.7 170.3c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3l216 216z"]
  };
  var faXmark = {
    prefix: 'fat',
    iconName: 'xmark',
    icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M338.1 413.4c3.1 3.1 8.2 3.1 11.3 0s3.1-8.2 0-11.3L203.3 256 349.4 109.9c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0L192 244.7 45.9 98.6c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3L180.7 256 34.6 402.1c-3.1 3.1-3.1 8.2 0 11.3s8.2 3.1 11.3 0L192 267.3 338.1 413.4z"]
  };
  var faChevronLeft = {
    prefix: 'fat',
    iconName: 'chevron-left',
    icon: [320, 512, [9001], "f053", "M18.3 250.3c-3.1 3.1-3.1 8.2 0 11.3l216 216c3.1 3.1 8.2 3.1 11.3 0s3.1-8.2 0-11.3L35.3 256 245.7 45.7c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0l-216 216z"]
  };
  var faChevronRight = {
    prefix: 'fat',
    iconName: 'chevron-right',
    icon: [320, 512, [9002], "f054", "M301.7 250.3c3.1 3.1 3.1 8.2 0 11.3l-216 216c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3L284.7 256 74.3 45.7c-3.1-3.1-3.1-8.2 0-11.3s8.2-3.1 11.3 0l216 216z"]
  };
  var faRotate = {
    prefix: 'fat',
    iconName: 'rotate',
    icon: [512, 512, [128260, "sync-alt"], "f2f1", "M43.6 224c-4.9 0-8.7-4.3-7.8-9.2c9.7-51.8 37.5-100.4 82.3-135.3c88.3-69 212.1-61.3 291.3 13.2L455 47c6.9-6.9 17.2-8.9 26.2-5.2s14.8 12.5 14.8 22.2V168c0 13.3-10.7 24-24 24H368c-9.7 0-18.5-5.8-22.2-14.8s-1.7-19.3 5.2-26.2l47-47C324.5 35.1 209.8 28.1 127.9 92.1c-41.4 32.3-67.2 77.2-76.3 125.1c-.7 3.9-4.1 6.8-8.1 6.8zm423.7 64c4.9 0 8.7 4.3 7.8 9.2c-9.7 51.8-37.5 100.4-82.3 135.4c-88.1 68.8-211.5 61.3-290.7-12.7L57 465c-6.9 6.9-17.2 8.9-26.2 5.2S16 457.7 16 448V344c0-13.3 10.7-24 24-24H144c9.7 0 18.5 5.8 22.2 14.8s1.7 19.3-5.2 26.2l-47.6 47.6c73.5 68.4 187.9 75.1 269.5 11.4c41.4-32.4 67.2-77.3 76.3-125.1c.7-3.9 4.1-6.8 8.1-6.8zM368 176H472c4.4 0 8-3.6 8-8V64c0-3.2-1.9-6.2-4.9-7.4s-6.4-.6-8.7 1.7l-104 104c-2.3 2.3-3 5.7-1.7 8.7s4.2 4.9 7.4 4.9zM144 336H40c-4.4 0-8 3.6-8 8V448c0 3.2 1.9 6.2 4.9 7.4s6.4 .6 8.7-1.7l104-104c2.3-2.3 3-5.7 1.7-8.7s-4.2-4.9-7.4-4.9z"]
  };
  var faCheck = {
    prefix: 'fat',
    iconName: 'check',
    icon: [448, 512, [10003, 10004], "f00c", "M437.7 106.3c3.1 3.1 3.1 8.2 0 11.3l-272 272c-3.1 3.1-8.2 3.1-11.3 0l-144-144c-3.1-3.1-3.1-8.2 0-11.3s8.2-3.1 11.3 0L160 372.7 426.3 106.3c3.1-3.1 8.2-3.1 11.3 0z"]
  };
  var faTriangleExclamation = {
    prefix: 'fat',
    iconName: 'triangle-exclamation',
    icon: [512, 512, [9888, "exclamation-triangle", "warning"], "f071", "M20.7 412.3c-3.1 5.3-4.7 11.2-4.7 17.3c0 19 15.4 34.4 34.4 34.4H461.6c19 0 34.4-15.4 34.4-34.4c0-6.1-1.6-12.1-4.7-17.3L290.3 67.7C283.2 55.5 270.1 48 256 48s-27.2 7.5-34.3 19.7L20.7 412.3zM6.9 404.2l201-344.6C217.9 42.5 236.2 32 256 32s38.1 10.5 48.1 27.6l201 344.6c4.5 7.7 6.9 16.5 6.9 25.4c0 27.8-22.6 50.4-50.4 50.4H50.4C22.6 480 0 457.4 0 429.6c0-8.9 2.4-17.7 6.9-25.4zM256 160c4.4 0 8 3.6 8 8V328c0 4.4-3.6 8-8 8s-8-3.6-8-8V168c0-4.4 3.6-8 8-8zM240 384a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z"]
  };
  var faExclamationTriangle = faTriangleExclamation;
  var faCircleXmark = {
    prefix: 'fat',
    iconName: 'circle-xmark',
    icon: [512, 512, [61532, "times-circle", "xmark-circle"], "f057", "M256 16a240 240 0 1 1 0 480 240 240 0 1 1 0-480zm0 496A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM178.3 178.3c-3.1 3.1-3.1 8.2 0 11.3L244.7 256l-66.3 66.3c-3.1 3.1-3.1 8.2 0 11.3s8.2 3.1 11.3 0L256 267.3l66.3 66.3c3.1 3.1 8.2 3.1 11.3 0s3.1-8.2 0-11.3L267.3 256l66.3-66.3c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0L256 244.7l-66.3-66.3c-3.1-3.1-8.2-3.1-11.3 0z"]
  };

  exports.faBars = faBars;
  exports.faCheck = faCheck;
  exports.faChevronDown = faChevronDown;
  exports.faChevronLeft = faChevronLeft;
  exports.faChevronRight = faChevronRight;
  exports.faCircleQuestion = faCircleQuestion;
  exports.faCircleXmark = faCircleXmark;
  exports.faExclamationTriangle = faExclamationTriangle;
  exports.faGrid = faGrid;
  exports.faInfoCircle = faInfoCircle;
  exports.faPencil = faPencil;
  exports.faRotate = faRotate;
  exports.faRotateExclamation = faRotateExclamation;
  exports.faSignOut = faSignOut;
  exports.faSpinnerThird = faSpinnerThird;
  exports.faUser = faUser;
  exports.faUserTie = faUserTie;
  exports.faUsers = faUsers;
  exports.faXmark = faXmark;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
