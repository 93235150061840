define('@fortawesome/pro-light-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faGrid = {
    prefix: 'fal',
    iconName: 'grid',
    icon: [448, 512, ["grid-3"], "e195", "M88 64c4.4 0 8 3.6 8 8v48c0 4.4-3.6 8-8 8H40c-4.4 0-8-3.6-8-8V72c0-4.4 3.6-8 8-8H88zM40 32C17.9 32 0 49.9 0 72v48c0 22.1 17.9 40 40 40H88c22.1 0 40-17.9 40-40V72c0-22.1-17.9-40-40-40H40zM88 224c4.4 0 8 3.6 8 8v48c0 4.4-3.6 8-8 8H40c-4.4 0-8-3.6-8-8V232c0-4.4 3.6-8 8-8H88zM40 192c-22.1 0-40 17.9-40 40v48c0 22.1 17.9 40 40 40H88c22.1 0 40-17.9 40-40V232c0-22.1-17.9-40-40-40H40zm0 192H88c4.4 0 8 3.6 8 8v48c0 4.4-3.6 8-8 8H40c-4.4 0-8-3.6-8-8V392c0-4.4 3.6-8 8-8zM0 392v48c0 22.1 17.9 40 40 40H88c22.1 0 40-17.9 40-40V392c0-22.1-17.9-40-40-40H40c-22.1 0-40 17.9-40 40zM248 64c4.4 0 8 3.6 8 8v48c0 4.4-3.6 8-8 8H200c-4.4 0-8-3.6-8-8V72c0-4.4 3.6-8 8-8h48zM200 32c-22.1 0-40 17.9-40 40v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V72c0-22.1-17.9-40-40-40H200zm0 192h48c4.4 0 8 3.6 8 8v48c0 4.4-3.6 8-8 8H200c-4.4 0-8-3.6-8-8V232c0-4.4 3.6-8 8-8zm-40 8v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V232c0-22.1-17.9-40-40-40H200c-22.1 0-40 17.9-40 40zm88 152c4.4 0 8 3.6 8 8v48c0 4.4-3.6 8-8 8H200c-4.4 0-8-3.6-8-8V392c0-4.4 3.6-8 8-8h48zm-48-32c-22.1 0-40 17.9-40 40v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V392c0-22.1-17.9-40-40-40H200zM360 64h48c4.4 0 8 3.6 8 8v48c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8V72c0-4.4 3.6-8 8-8zm-40 8v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V72c0-22.1-17.9-40-40-40H360c-22.1 0-40 17.9-40 40zm88 152c4.4 0 8 3.6 8 8v48c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8V232c0-4.4 3.6-8 8-8h48zm-48-32c-22.1 0-40 17.9-40 40v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V232c0-22.1-17.9-40-40-40H360zm0 192h48c4.4 0 8 3.6 8 8v48c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8V392c0-4.4 3.6-8 8-8zm-40 8v48c0 22.1 17.9 40 40 40h48c22.1 0 40-17.9 40-40V392c0-22.1-17.9-40-40-40H360c-22.1 0-40 17.9-40 40z"]
  };
  var faPencil = {
    prefix: 'fal',
    iconName: 'pencil',
    icon: [512, 512, [9999, 61504, "pencil-alt"], "f303", "M395.8 39.6c9.4-9.4 24.6-9.4 33.9 0l42.6 42.6c9.4 9.4 9.4 24.6 0 33.9L417.6 171 341 94.4l54.8-54.8zM318.4 117L395 193.6l-219 219V400c0-8.8-7.2-16-16-16H128V352c0-8.8-7.2-16-16-16H99.4l219-219zM66.9 379.5c1.2-4 2.7-7.9 4.7-11.5H96v32c0 8.8 7.2 16 16 16h32v24.4c-3.7 1.9-7.5 3.5-11.6 4.7L39.6 472.4l27.3-92.8zM452.4 17c-21.9-21.9-57.3-21.9-79.2 0L60.4 329.7c-11.4 11.4-19.7 25.4-24.2 40.8L.7 491.5c-1.7 5.6-.1 11.7 4 15.8s10.2 5.7 15.8 4l121-35.6c15.4-4.5 29.4-12.9 40.8-24.2L495 138.8c21.9-21.9 21.9-57.3 0-79.2L452.4 17zM331.3 202.7c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0l-128 128c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l128-128z"]
  };
  var faBars = {
    prefix: 'fal',
    iconName: 'bars',
    icon: [448, 512, ["navicon"], "f0c9", "M0 80c0-8.8 7.2-16 16-16H432c8.8 0 16 7.2 16 16s-7.2 16-16 16H16C7.2 96 0 88.8 0 80zM0 240c0-8.8 7.2-16 16-16H432c8.8 0 16 7.2 16 16s-7.2 16-16 16H16c-8.8 0-16-7.2-16-16zM448 400c0 8.8-7.2 16-16 16H16c-8.8 0-16-7.2-16-16s7.2-16 16-16H432c8.8 0 16 7.2 16 16z"]
  };
  var faArrowRightFromBracket = {
    prefix: 'fal',
    iconName: 'arrow-right-from-bracket',
    icon: [512, 512, ["sign-out"], "f08b", "M507.3 267.3c6.2-6.2 6.2-16.4 0-22.6l-128-128c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L457.4 240 176 240c-8.8 0-16 7.2-16 16s7.2 16 16 16l281.4 0L356.7 372.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l128-128zM176 64c8.8 0 16-7.2 16-16s-7.2-16-16-16L80 32C35.8 32 0 67.8 0 112L0 400c0 44.2 35.8 80 80 80l96 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-96 0c-26.5 0-48-21.5-48-48l0-288c0-26.5 21.5-48 48-48l96 0z"]
  };
  var faSignOut = faArrowRightFromBracket;
  var faUsers = {
    prefix: 'fal',
    iconName: 'users',
    icon: [640, 512, [], "f0c0", "M96 80a48 48 0 1 1 96 0A48 48 0 1 1 96 80zm128 0A80 80 0 1 0 64 80a80 80 0 1 0 160 0zm96 80a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm0 160a96 96 0 1 0 0-192 96 96 0 1 0 0 192zm-58.7 64H378.7c54.2 0 98.4 42.5 101.2 96H160.1c2.8-53.5 47-96 101.2-96zm0-32C187.7 352 128 411.7 128 485.3c0 14.7 11.9 26.7 26.7 26.7H485.3c14.7 0 26.7-11.9 26.7-26.7C512 411.7 452.3 352 378.7 352H261.3zM512 32a48 48 0 1 1 0 96 48 48 0 1 1 0-96zm0 128A80 80 0 1 0 512 0a80 80 0 1 0 0 160zm16 64c44.2 0 80 35.8 80 80c0 8.8 7.2 16 16 16s16-7.2 16-16c0-61.9-50.1-112-112-112H444c2.6 10.2 4 21 4 32h80zm-336 0c0-11 1.4-21.8 4-32H112C50.1 192 0 242.1 0 304c0 8.8 7.2 16 16 16s16-7.2 16-16c0-44.2 35.8-80 80-80h80z"]
  };
  var faUser = {
    prefix: 'fal',
    iconName: 'user',
    icon: [448, 512, [128100, 62144], "f007", "M320 128a96 96 0 1 0 -192 0 96 96 0 1 0 192 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM32 480H416c-1.2-79.7-66.2-144-146.3-144H178.3c-80 0-145 64.3-146.3 144zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"]
  };
  var faUserTie = {
    prefix: 'fal',
    iconName: 'user-tie',
    icon: [448, 512, [], "f508", "M224 32a96 96 0 1 1 0 192 96 96 0 1 1 0-192zM96 128a128 128 0 1 0 256 0A128 128 0 1 0 96 128zm96 176c-8.8 0-16 7.2-16 16s7.2 16 16 16h9.8l-21.7 65-32.1-69.5c-2.9-6.3-9.5-10-16.3-8.8C56.8 336.5 0 402.3 0 481.3c0 17 13.8 30.7 30.7 30.7H417.3c17 0 30.7-13.8 30.7-30.7c0-79-56.8-144.7-131.8-158.6c-6.8-1.3-13.4 2.5-16.3 8.8L267.9 401l-21.7-65H256c8.8 0 16-7.2 16-16s-7.2-16-16-16H224 192zm17.5 160.5l-9.7-21.1L224 370.6l24.3 72.8-9.7 21.1c-5.7 12.4-23.3 12.4-29.1 0zm-29.1 13.4c.3 .7 .7 1.4 1 2.1H32c.6-58.1 39.5-107.1 92.7-122.8l55.7 120.7zM416 480H266.6c.3-.7 .7-1.4 1-2.1l55.7-120.7c53.2 15.7 92.1 64.6 92.7 122.8z"]
  };
  var faCircleQuestion = {
    prefix: 'fal',
    iconName: 'circle-question',
    icon: [512, 512, [62108, "question-circle"], "f059", "M480 256A224 224 0 1 0 32 256a224 224 0 1 0 448 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm168.7-86.2c6.5-24.6 28.7-41.8 54.2-41.8H280c35.5 0 64 29 64 64.3c0 24-13.4 46.2-34.9 57.2L272 268.3V288c0 8.8-7.2 16-16 16s-16-7.2-16-16V258.5c0-6 3.4-11.5 8.7-14.3l45.8-23.4c10.7-5.4 17.5-16.6 17.5-28.7c0-17.8-14.4-32.3-32-32.3H222.9c-10.9 0-20.5 7.4-23.2 17.9l-.2 .7c-2.2 8.5-11 13.7-19.5 11.4s-13.7-11-11.4-19.5l.2-.7zM232 352a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"]
  };
  var faSpinnerThird = {
    prefix: 'fal',
    iconName: 'spinner-third',
    icon: [512, 512, [], "f3f4", "M463.9 376c7.6 4.4 17.5 1.8 21.4-6.1c17.1-34.3 26.7-73 26.7-113.9C512 120 405.9 8.8 272 .5c-8.8-.5-16 6.7-16 15.5s7.2 15.9 16 16.6C388.2 40.8 480 137.7 480 256c0 35.1-8.1 68.3-22.5 97.9c-3.9 8-1.3 17.7 6.4 22.1z"]
  };
  var faCircleInfo = {
    prefix: 'fal',
    iconName: 'circle-info',
    icon: [512, 512, ["info-circle"], "f05a", "M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM208 352c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H272V240c0-8.8-7.2-16-16-16H216c-8.8 0-16 7.2-16 16s7.2 16 16 16h24v96H208zm48-168a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"]
  };
  var faInfoCircle = faCircleInfo;
  var faRotateExclamation = {
    prefix: 'fal',
    iconName: 'rotate-exclamation',
    icon: [512, 512, [], "e23c", "M403.5 133.1C368.3 90.9 315.3 64 256 64C165.2 64 89.1 127 69.1 211.7c-2 8.6-10.6 13.9-19.2 11.9s-13.9-10.6-11.9-19.2C61.3 105.5 150.1 32 256 32c69.2 0 131 31.4 172.1 80.6L464 154.9V96c0-8.8 7.2-16 16-16s16 7.2 16 16v96c0 8.8-7.2 16-16 16H384c-8.8 0-16-7.2-16-16s7.2-16 16-16h55.9l-36.3-42.8 0 0-.1-.1zM272 144V272c0 8.8-7.2 16-16 16s-16-7.2-16-16V144c0-8.8 7.2-16 16-16s16 7.2 16 16zM256 328a24 24 0 1 1 0 48 24 24 0 1 1 0-48zM32 432c-8.8 0-16-7.2-16-16V320c0-8.8 7.2-16 16-16h96c8.8 0 16 7.2 16 16s-7.2 16-16 16H72.1l36.3 42.8 0 0 .1 .1C143.7 421.1 196.7 448 256 448c90.8 0 166.9-63 186.9-147.7c2-8.6 10.7-13.9 19.3-11.9s13.9 10.6 11.9 19.2C450.7 406.5 361.9 480 256 480c-69.2 0-131-31.4-172.1-80.6L48 357.1V416c0 8.8-7.2 16-16 16z"]
  };
  var faChevronDown = {
    prefix: 'fal',
    iconName: 'chevron-down',
    icon: [512, 512, [], "f078", "M267.3 395.3c-6.2 6.2-16.4 6.2-22.6 0l-192-192c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L256 361.4 436.7 180.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-192 192z"]
  };
  var faXmark = {
    prefix: 'fal',
    iconName: 'xmark',
    icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z"]
  };
  var faChevronLeft = {
    prefix: 'fal',
    iconName: 'chevron-left',
    icon: [320, 512, [9001], "f053", "M20.7 267.3c-6.2-6.2-6.2-16.4 0-22.6l192-192c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6L54.6 256 235.3 436.7c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0l-192-192z"]
  };
  var faChevronRight = {
    prefix: 'fal',
    iconName: 'chevron-right',
    icon: [320, 512, [9002], "f054", "M299.3 244.7c6.2 6.2 6.2 16.4 0 22.6l-192 192c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6L265.4 256 84.7 75.3c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0l192 192z"]
  };
  var faRotate = {
    prefix: 'fal',
    iconName: 'rotate',
    icon: [512, 512, [128260, "sync-alt"], "f2f1", "M426.1 301.2C406.2 376.5 337.6 432 256 432c-51 0-96.9-21.7-129-56.3l41-41c5.1-5.1 8-12.1 8-19.3c0-15.1-12.2-27.3-27.3-27.3H48c-8.8 0-16 7.2-16 16V404.7C32 419.8 44.2 432 59.3 432c7.2 0 14.2-2.9 19.3-8l25.7-25.7C142.3 438.7 196.2 464 256 464c97.4 0 179.2-67 201.8-157.4c2.4-9.7-5.2-18.6-15.2-18.6c-7.8 0-14.5 5.6-16.5 13.2zM385 136.3l-41 41c-5.1 5.1-8 12.1-8 19.3c0 15.1 12.2 27.3 27.3 27.3H464c8.8 0 16-7.2 16-16V107.3C480 92.2 467.8 80 452.7 80c-7.2 0-14.2 2.9-19.3 8l-25.7 25.7C369.7 73.3 315.8 48 256 48C158.6 48 76.8 115 54.2 205.4c-2.4 9.7 5.2 18.6 15.2 18.6c7.8 0 14.5-5.6 16.5-13.2C105.8 135.5 174.4 80 256 80c51 0 96.9 21.7 129.1 56.3zM448 192H374.6L448 118.6V192zM64 320h73.4L64 393.4V320z"]
  };
  var faCheck = {
    prefix: 'fal',
    iconName: 'check',
    icon: [448, 512, [10003, 10004], "f00c", "M443.3 100.7c6.2 6.2 6.2 16.4 0 22.6l-272 272c-6.2 6.2-16.4 6.2-22.6 0l-144-144c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L160 361.4 420.7 100.7c6.2-6.2 16.4-6.2 22.6 0z"]
  };
  var faTriangleExclamation = {
    prefix: 'fal',
    iconName: 'triangle-exclamation',
    icon: [512, 512, [9888, "exclamation-triangle", "warning"], "f071", "M34.5 420.4c-1.6 2.8-2.5 6-2.5 9.3c0 10.2 8.2 18.4 18.4 18.4H461.6c10.2 0 18.4-8.2 18.4-18.4c0-3.3-.9-6.4-2.5-9.3L276.5 75.8C272.2 68.5 264.4 64 256 64s-16.2 4.5-20.5 11.8L34.5 420.4zM6.9 404.2l201-344.6C217.9 42.5 236.2 32 256 32s38.1 10.5 48.1 27.6l201 344.6c4.5 7.7 6.9 16.5 6.9 25.4c0 27.8-22.6 50.4-50.4 50.4H50.4C22.6 480 0 457.4 0 429.6c0-8.9 2.4-17.7 6.9-25.4zM256 160c8.8 0 16 7.2 16 16V304c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16zM232 384a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"]
  };
  var faExclamationTriangle = faTriangleExclamation;
  var faCircleXmark = {
    prefix: 'fal',
    iconName: 'circle-xmark',
    icon: [512, 512, [61532, "times-circle", "xmark-circle"], "f057", "M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM180.7 180.7c-6.2 6.2-6.2 16.4 0 22.6L233.4 256l-52.7 52.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L256 278.6l52.7 52.7c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L278.6 256l52.7-52.7c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L256 233.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0z"]
  };

  exports.faBars = faBars;
  exports.faCheck = faCheck;
  exports.faChevronDown = faChevronDown;
  exports.faChevronLeft = faChevronLeft;
  exports.faChevronRight = faChevronRight;
  exports.faCircleQuestion = faCircleQuestion;
  exports.faCircleXmark = faCircleXmark;
  exports.faExclamationTriangle = faExclamationTriangle;
  exports.faGrid = faGrid;
  exports.faInfoCircle = faInfoCircle;
  exports.faPencil = faPencil;
  exports.faRotate = faRotate;
  exports.faRotateExclamation = faRotateExclamation;
  exports.faSignOut = faSignOut;
  exports.faSpinnerThird = faSpinnerThird;
  exports.faUser = faUser;
  exports.faUserTie = faUserTie;
  exports.faUsers = faUsers;
  exports.faXmark = faXmark;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
